import React, { useState } from 'react';

const GetDirections = ({ map, isMobile, calculateCustomPath, booths }) => {
  const [startPoint, setStartPoint] = useState('');
  const [endPoint, setEndPoint] = useState('');
  const [mode, setMode] = useState('walking');
  const [filteredStartBooths, setFilteredStartBooths] = useState([]);
  const [filteredEndBooths, setFilteredEndBooths] = useState([]);
  const [showStartDropdown, setShowStartDropdown] = useState(false);
  const [showEndDropdown, setShowEndDropdown] = useState(false);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const handleCalculatePath = () => {
    if (startPoint && endPoint) {
      const path = calculateCustomPath(startPoint, endPoint, mode);
      animatePathOnMap(path);
    }
  };

  const animatePathOnMap = (path) => {
    if (!path || path.length === 0 || path.features.length === 0) {
      return; // Do nothing if path is not valid
    }

    if (!map.getLayer('custom-path')) {
      map.addSource('custom-path', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [{ type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }], 
        },
      });

      map.addLayer({
        id: 'custom-path',
        type: 'line',
        source: 'custom-path',
        layout: { 'line-join': 'round', 'line-cap': 'round' },
        paint: { 'line-color': '#0000FF', 'line-width': 4 },
      });
    }

    const animatedPath = { type: 'FeatureCollection', features: [{ type: 'Feature', geometry: { type: 'LineString', coordinates: [] } }] };

    let featureIndex = 0;
    let step = 0;

    const animateSegment = () => {
      const currentFeature = path.features[featureIndex];
      const totalSteps = currentFeature.geometry.coordinates.length;

      if (step < totalSteps) {
        animatedPath.features[0].geometry.coordinates.push(currentFeature.geometry.coordinates[step]);
        map.getSource('custom-path').setData(animatedPath);
        step += 1;
        requestAnimationFrame(animateSegment);
      } else if (featureIndex < path.features.length - 1) {
        featureIndex += 1;
        step = 0;
        requestAnimationFrame(animateSegment);
      }
    };

    animateSegment();
  };

  const handleFlip = () => {
    setStartPoint(endPoint);
    setEndPoint(startPoint);
  };

  const handleStartChange = (e) => {
    const value = e.target.value;
    setStartPoint(value);
  
    // Update dropdowns: Show for 'From' input, hide for 'To' input
    if (value) {
      const filtered = booths.filter((feature) =>
        feature.properties?.Name && feature.properties?.Name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredStartBooths(filtered);
      setShowStartDropdown(true);
    } else {
      setShowStartDropdown(false);
    }
  
    // Hide 'To' dropdown
    setShowEndDropdown(false);
  };
  
  const handleEndChange = (e) => {
    const value = e.target.value;
    setEndPoint(value);
  
    // Update dropdowns: Show for 'To' input, hide for 'From' input
    if (value) {
      const filtered = booths.filter((feature) =>
        feature.properties?.Name && feature.properties?.Name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredEndBooths(filtered);
      setShowEndDropdown(true);
    } else {
      setShowEndDropdown(false);
    }
  
    // Hide 'From' dropdown
    setShowStartDropdown(false);
  };
  

  const handleSelectStart = (booth) => {
    setStartPoint(booth.properties.Name);
    setShowStartDropdown(false);
  };

  const handleSelectEnd = (booth) => {
    setEndPoint(booth.properties.Name);
    setShowEndDropdown(false);
  };

  const truncateDescription = (Description, maxLength = 50) => {
    return Description.length > maxLength ? Description.substring(0, maxLength) + '...' : Description;
  };

  return (
    <div
      className="fixed p-6 w-96 rounded-xl bg-white shadow-lg top-4"
      style={{
        width: `${isMobile ? "calc(100% - 2rem)" : "24rem"}`,
        margin: `${isMobile ? "0 auto" : "0 0 0 4rem"}`,
        left: `${isMobile ? "0" : "unset"}`,
        right: `${isMobile ? "0" : "unset"}`,
      }}
    >
      {/* Container */}
      <div className="flex flex-col space-y-0">
        {/* From Section */}
        <div className="flex flex-col w-full items-start space-y-0">
          <div className="w-full flex items-center mb-2">
          <div className="text-blue-600 font-bold text-lg flex flex-col items-start mr-2">
              <label className="block text-gray-500 text-xs">From</label>
              <span className="mt-1">A</span>
          </div>
          <input
            type="text"
            value={startPoint}
            placeholder="Write booth next to you"
            className={`w-full ml-2 py-2 px-2 text-gray-700 focus:outline-none placeholder-gray-500 rounded-lg`}
            onChange={handleStartChange}
          />
          </div>

          {showStartDropdown && (
            <ul className="w-full bg-white rounded max-h-40 overflow-auto mt-1">
              {filteredStartBooths.map((booth, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectStart(booth)}
                  className="p-2 border-t-2 border-dashed hover:bg-gray-200 cursor-pointer"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {booth.properties.Name}
                    </span>
                    <span className={`text-gray-500 text-xs`}>
                      {truncateDescription(booth.properties.Description)}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Flip Button */}
        <div className="pb-2 relative flex items-center justify-center mb-4">
          <hr className="border-gray-300 w-full" />
          <button
            onClick={handleFlip}
            className="absolute bg-white p-1 rounded-full hover:bg-gray-200 transition"
            title="Flip start and end points"
            style={{ top: "-10px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              className="w-4 h-4 text-gray-600"
            >
              <path
                fillRule="evenodd"
                d="M6.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.25 4.81V16.5a.75.75 0 0 1-1.5 0V4.81L3.53 8.03a.75.75 0 0 1-1.06-1.06l4.5-4.5Zm9.53 4.28a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V7.5a.75.75 0 0 1 .75-.75Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        {/* To Section */}
        <div className="pb-2 flex flex-col w-full items-start space-y-0">
          <div className="w-full flex items-center mb-2">
            <div className="text-blue-600 font-bold text-lg flex flex-col items-start mr-2">
              <label className="block text-gray-500 text-xs">To</label>
              <span className="mt-1">B</span>
            </div>
            <input
              type="text"
              value={endPoint}
              placeholder="Choose destination"
              className={`w-full ml-5 py-2 px-2 text-gray-700 focus:outline-none placeholder-gray-500 rounded-lg`}
              onChange={handleEndChange}
            />
          </div>
          {/* Dropdown section below */}
          {showEndDropdown && (
            <ul className="w-full bg-white rounded max-h-40 overflow-auto mt-1">
              {filteredEndBooths.map((booth, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectEnd(booth)}
                  className="p-2 border-t-2 border-dashed hover:bg-gray-200 cursor-pointer"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {booth.properties.Name}
                    </span>
                    <span className="text-gray-500 text-xs">
                      {truncateDescription(booth.properties.Description)}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Get Directions Button */}
        <button
          onClick={handleCalculatePath}
          className="w-full mt-4 py-2 px-4 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-300"
        >
          Get Directions
        </button>
      </div>
    </div>
  );
};

export default GetDirections;
