import { EyeIcon, BookmarkIcon } from '@heroicons/react/24/outline'; // Importing icons from Heroicons v2
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid"; // Solid version
import 'tailwindcss/tailwind.css';

// ===================================================================================//
// ===================================================================================//
const FavoriteCard = ({ booth, map, onView, toggleBookmark, isBookmarked,isMobile,isListView }) => {
    return (

     
      <div className="flex items-center bg-white rounded-lg shadow-md overflow-hidden h-50 mb-2 flex-none"
      style={{
         width: `${isMobile ? (isListView ? "98%" : "80vw") : "99%"}`,
         marginLeft: isListView ? "0.55rem" : ""
      }} >
        {/* Logo Section */}
        <div className="w-1/3 flex justify-center items-center">
          <img src={booth.properties.image} alt={`${booth.properties.Name} Logo`} className="h-16 w-16 object-contain" />
        </div>
  
        {/* Info Section */}
        <div className="w-2/3 pl-4">
          <h1 className="text-xl font-bold text-gray-900 leading-6 line-clamp-2 mt-2 mb-4 h-15 pb-1 pl-2">{booth.properties.Name}</h1>
          <p className="text-gray-600 text-sm line-clamp-2 mt-2 mb-2">
            {booth.properties.Description}
          </p>
          
          {/* Buttons Section */}
          <div className="flex items-center px-2 py-2 mt-2">
            <button className="flex items-center text-red-500 border border-red-500 px-3 py-1 rounded-md mr-2 hover:bg-red-500 hover:text-white "
              onClick={() => onView(booth, map)}
                >
              <EyeIcon className="w-4 h-4 mr-1" />
              View
            </button>
            <button className="flex items-center ${isBookmarked ? 'text-white bg-red-500' : 'text-red-500 border border-red-500'}  hover:text-red-500" 
            style={{
              marginLeft: '0.8rem', // Move the bar to the right beyond the sidebar            
            }}
            onClick={() => toggleBookmark(booth)}
  
            >
            {isBookmarked ? (
                <BookmarkIconSolid className="w-6 h-6 text-red-500" />
              ) : (
                <BookmarkIcon className="w-6 h-6 text-gray-500" />
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  const FavoriteCardH = ({ booth, map, onView, toggleBookmark, isBookmarked }) => {
    return (
      <div className="flex items-start bg-white rounded-lg shadow-md p-4 mb-4 w-full max-w-[80%] mx-auto" >
        {/* Logo Section */}
        <div className="w-1/4 flex justify-center items-center">
          <img src={booth.properties.image} alt={`${booth.properties.Name} Logo`} className="h-16 w-16 object-contain" />
        </div>
  
        {/* Info Section */}
        <div className="w-3/4 pl-4 flex flex-col">
          <h3 className="text-base font-semibold">{booth.properties.Name}</h3>
          {/* Description */}
          <p className="text-xs text-gray-600">
            {booth.properties.Description}
          </p>
  
          {/* Buttons Section */}
          <div className="flex items-center mt-3 space-x-2">
            {/* View Button */}
            <button className="flex items-center text-red-500 border border-red-500 px-3 py-1 rounded-md hover:bg-red-500 hover:text-white"
              onClick={() => onView(booth, map)}
            >
              <EyeIcon className="w-4 h-4 mr-1" />
              View
            </button>
  
            {/* Bookmark Button */}
            <button
              className={`flex items-center px-3 py-1 rounded-md ${isBookmarked ? 'bg-red-500 text-white' : 'border border-red-500 text-red-500'} hover:bg-red-500 hover:text-white`}
              onClick={() => toggleBookmark(booth)}
            >
              {isBookmarked ? (
                <BookmarkIconSolid className="w-5 h-5" />
              ) : (
                <BookmarkIcon className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default FavoriteCard; 