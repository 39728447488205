import { useState } from "react";

const CategorySelector = ({ categories, setFilter, filteredBooths, onView, map, toggleBookmark, bookmarkedBooths, closeCategorySelector,isMobile }) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
  
    // Find the "Sectors" category
    const sectorsCategory = categories.find(
      (category) => category.name === "Sectors"
    );
  
    const handleFilterClick = () => {
      setFilter(selectedCategories);
      closeCategorySelector();
    };
  
    const handleCategoryClick = (subcategorySlug) => {
      setSelectedCategories((prevSelected) =>
        prevSelected.includes(subcategorySlug)
          ? prevSelected.filter((slug) => slug !== subcategorySlug)
          : [...prevSelected, subcategorySlug]
      );
    };
  
    const handleSelectAll = (group) => {
      const groupSlugs = group.children.map((subCat) => subCat.slug);
      setSelectedCategories((prevSelected) => {
        const areAllSelected = groupSlugs.every((slug) => prevSelected.includes(slug));
        return areAllSelected
          ? prevSelected.filter((slug) => !groupSlugs.includes(slug))
          : [...prevSelected, ...groupSlugs.filter((slug) => !prevSelected.includes(slug))];
      });
    };
  
    
  
    return (
      <div className="bg-red-900 absolute top-0 left-0 bg-opacity-80  w-full h-full">
      <div
        className="fixed top-0 left-1 bg-white bg-opacity-1 rounded-lg pl-4 z-10 size-full flex flex-col"
        style={{
          marginTop: "5rem",
          marginLeft: `calc((${!isMobile ? 1 : 0.17} + 0.1) * (3rem + 1rem))`, // Move the bar to the right beyond the sidebar
          height: "80%",
          width: "90%"
        }}
      >
        <div className="text-lg font-semibold leading-6 text-gray-900 border-b-2 mb-2 pt-2 pb-2 border-red-200">
          Select Categories
        </div>
        <div
          className="pl-2 pr-1 custom-scrollbar"
          style={{
            overflowY: "auto",
            paddingTop: "1rem",
            paddingBottom: "15rem"
          }}
        >
          {sectorsCategory && sectorsCategory.children.map((group) => (
            <div key={group.id} className="group mb-4 border-b-2 pb-6 border-gray-200">
              <div className="group-header flex justify-between items-center mb-2">
                <span className="text-md font-semibold">{group.name}</span>
                <button
                  className="text-sm font-semibold border-b-2 border-gray-200 text-gray-200 hover:text-gray-700"
                  onClick={() => handleSelectAll(group)}
                >
                  select all
                </button>
              </div>
              <div className="subcategories text-sm flex flex-wrap gap-2">
                {group.children.map((subCategory) => (
                  <button
                    key={subCategory.id}
                    className={`leading-5 px-3 py-1 rounded-lg border border-gray-300 text-left text-xs ${
                      selectedCategories.includes(subCategory.slug)
                        ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white"
                        : "bg-gray-50 text-gray-700 hover:bg-gray-200" 
                    }`}
                    onClick={() => handleCategoryClick(subCategory.slug)}
                  >
                    {subCategory.name}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full pb-2 border-t-2 flex items-center justify-center px-4 text-sm">
          <button
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md text-center font-semibold mt-4"
            onClick={handleFilterClick}
          >
            Filter Exhibitors
          </button>
        </div>
      </div>
    </div>
    );
  };

  export default CategorySelector;