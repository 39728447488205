import React, {  useRef } from 'react';

const SupportTaskBar = ({ supportCategories, setActivesupport }) => {
    const amenitiesBarRef = useRef(null);
  
    const scrollAmenitiesBar = (direction) => {
      if (amenitiesBarRef.current) {
        const scrollAmount = 100; 
        if (direction === 'left') {
          amenitiesBarRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        } else if (direction === 'right') {
          amenitiesBarRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
      }
    };
  
    return (
      <div
        className="fixed left-16 top-[calc(5rem)] z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 inset-x-0 z-[2] max-w-xl"
      >
        {/* Left Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('left')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
          </svg>
        </div>
  
        {/* Amenities Buttons */}
        <div
          className="flex space-x-2 overflow-x-auto scrollbar-hide"
          ref={amenitiesBarRef}
          style={{ maxWidth: '100%', whiteSpace: 'nowrap' }}
        >
          {supportCategories.map((amenity, index) => (
            <button
              key={index}
              onClick={() => setActivesupport(amenity)}
              className={`inline-flex px-2 py-1 rounded-lg text-sm leading-5 items-center whitespace-nowrap font-medium bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200`}
            >
              {amenity}
            </button>
          ))}
        </div>
  
        {/* Right Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar('right')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
          </svg>
        </div>
      </div>
    );
  };

  export default  SupportTaskBar;