import { Graph } from 'graphology';
import { bidirectional, singleSource } from 'graphology-shortest-path';

export  const getPath = (G, start, end) => {
    let p = null;
    try {
        // Attempt bidirectional shortest path
        p =  bidirectional(G, start, end);
    } catch {
        console.log(`Bidirectional path not found, attempting singleSource for nodes ${start} to ${end}`);
    }
    if( !p) 
    {
        const pathsFromStart = singleSource(G, start);
        p = pathsFromStart[end] || null;  // Get the path to `end` from `start`
    }
    return p;
};

export const addGraphLayers = (map, verticesData, edgesData, start, end, mode) => {
    const G = new Graph({ type: 'undirected' }); //undirected is necessary for bidirection and all other combinations of source->dest to work
    // Add vertices to the graph
    verticesData.features.forEach(vertex => {
        const vertexId = parseInt(vertex.properties.id, 10);
        const [cx, cy] = vertex.geometry.coordinates;
        G.addNode(vertexId, { coordinates: [cx, cy], label: vertex.properties.name || `v${vertexId}` });
    });

    // Add edges to the graph
    edgesData.features.forEach(edge => {
        const fromId = parseInt(edge.properties.from_id, 10);
        const toId = parseInt(edge.properties.to_id, 10);
        G.addEdge(fromId, toId);
    });

    // // Add vertices as a Mapbox symbol (points)
    // const verticesGeoJSON = {
    //     type: 'FeatureCollection',
    //     features: verticesData.features.map(vertex => ({
    //         type: 'Feature',
    //         geometry: vertex.geometry,
    //         properties: {
    //             id: vertex.properties.id,
    //             name: vertex.properties.name
    //         }
    //     }))
    // };

    // // Add vertices and edges layers to map if not already added
    // if (!map.getSource('vertices')) {
    //     map.addSource('vertices', {
    //         type: 'geojson',
    //         data: verticesGeoJSON
    //     });
    //     map.addLayer({
    //         id: 'vertices',
    //         type: 'symbol',
    //         source: 'vertices',
    //         layout: {
    //             'icon-image': 'circle-15',
    //             'text-field': ['get', 'name'],
    //             'text-offset': [0, 1.5],
    //             'text-anchor': 'top'
    //         }
    //     });
    // }

    // const edgesGeoJSON = {
    //     type: 'FeatureCollection',
    //     features: edgesData.features.map(edge => {
    //         const fromCoordinates = G.getNodeAttributes(parseInt(edge.properties.from_id)).coordinates;
    //         const toCoordinates = G.getNodeAttributes(parseInt(edge.properties.to_id)).coordinates;
    //         return {
    //             type: 'Feature',
    //             geometry: {
    //                 type: 'LineString',
    //                 coordinates: [fromCoordinates, toCoordinates]
    //             }
    //         };
    //     })
    // };

    // if (!map.getSource('edges')) {
    //     map.addSource('edges', {
    //         type: 'geojson',
    //         data: edgesGeoJSON
    //     });
    //     map.addLayer({
    //         id: 'edges',
    //         type: 'line',
    //         source: 'edges',
    //         paint: {
    //             'line-color': '#888',
    //             'line-width': 2
    //         }
    //     });
    // }

    // Highlight shortest path if found
    if (G.hasNode(start) && G.hasNode(end)) {
        // const path = shortestPath.bidirectional(G, start, end);
        const path = getPath(G, start, end);
        if (path) {
            const pathEdges = path.map((node, i) => {
                if (i < path.length - 1) {
                    const fromCoordinates = G.getNodeAttributes(node).coordinates;
                    const toCoordinates = G.getNodeAttributes(path[i + 1]).coordinates;
                    return [fromCoordinates, toCoordinates];
                }
                return null;
            }).filter(edge => edge !== null);

            const pathGeoJSON = {
                type: 'FeatureCollection',
                features: pathEdges.map(coords => ({
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: coords
                    }
                }))
            };
            return pathGeoJSON;
        }
    }
    
};

const GraphMapComponent = ({ map, verticesData, edgesData }) => {

    return null; // No need to render any elements within this component
};

export default GraphMapComponent;
