export async function loadExhibitionMap(name) {
    try {
        // Step 1: Determine which exhibition file to load based on the 'name' parameter
        const exhibitionFile = name === 'GLTX' 
            ? './GLTX.json' 
            : './exhibition.json'; // Default to './exhibition.json' if no valid 'name' is provided

        // Fetch the exhibition metadata
        const metadataResponse = await fetch(exhibitionFile);
        if (!metadataResponse.ok) throw new Error('Failed to load exhibition metadata');
        const exhibitionMetadata = await metadataResponse.json();

        // Validate that exhibitionMetadata has a "filepath" property for GeoJSON
        if (!exhibitionMetadata || !exhibitionMetadata.filepath) {
            console.error('Invalid exhibition metadata format:', exhibitionMetadata);
            throw new Error('Exhibition metadata does not contain the expected "filepath" property');
        }

        // Step 2: Fetch the actual GeoJSON data using the filepath from metadata
        const geojsonResponse = await fetch(exhibitionMetadata.filepath);
        if (!geojsonResponse.ok) throw new Error('Failed to load exhibition GeoJSON file');
        const exhibitionData = await geojsonResponse.json();

        // Validate that exhibitionData has the expected structure
        if (!exhibitionData || !exhibitionData.features) {
            console.error('Invalid GeoJSON data format:', exhibitionData);
            throw new Error('GeoJSON data does not contain the expected "features" property');
        }

        // Fetch the tree GeoJSON data if the treefilepath is provided
        let exhibitiontreeData = null;
        if (exhibitionMetadata.treefilepath) {
            const treegeojsonResponse = await fetch(exhibitionMetadata.treefilepath);
            if (!treegeojsonResponse.ok) throw new Error('Failed to load exhibition treeGeoJSON file');
            exhibitiontreeData = await treegeojsonResponse.json();
        }

        // Fetch the border GeoJSON data if the borderpath is provided
        let borderData = null;
        if (exhibitionMetadata.borderpath) {
            const bordergeojsonResponse = await fetch(exhibitionMetadata.borderpath);
            if (!bordergeojsonResponse.ok) throw new Error('Failed to load exhibition borderGeoJSON file');
            borderData = await bordergeojsonResponse.json();
        }

        // Fetch the tower GeoJSON data if the towerpoint is provided
        let towerdata = null;
        if (exhibitionMetadata.towerpoint) {
            const towergeojsonResponse = await fetch(exhibitionMetadata.towerpoint);
            if (!towergeojsonResponse.ok) throw new Error('Failed to load exhibition towerGeoJSON file');
            towerdata = await towergeojsonResponse.json();
        }

        // Fetch the parking GeoJSON data if the parking is provided
        let parkingdata = null;
        if (exhibitionMetadata.parking) {
            const parkinggeojsonResponse = await fetch(exhibitionMetadata.parking);
            if (!parkinggeojsonResponse.ok) throw new Error('Failed to load exhibition parkingGeoJSON file');
            parkingdata = await parkinggeojsonResponse.json();
        }

        // Extract coordinates and validate
        let maineCoordinates = []; // Default coordinates
        if (exhibitionMetadata.center && exhibitionMetadata.center.desktop) {
            const { latitude: lat, longitude: lng } = exhibitionMetadata.center.desktop;
            // Ensure the coordinates are valid
            if (!isNaN(lat) && !isNaN(lng)) {
                maineCoordinates = [lat, lng];
            } else {
                console.error('Invalid coordinates in exhibition metadata:', lat, lng);
            }
        }

        // Return all loaded data
        return { 
            exhibitionData, 
            maineCoordinates, 
            exhibitiontreeData, 
            borderData, 
            towerdata, 
            parkingdata 
        };
    } catch (err) {
        console.error('Error loading exhibition data:', err);
        throw err;
    }
}
