import { useState } from 'react';

const LoungesSelector=({ loungesdata,isMobile,isListVieDef = isMobile ? false : true, ArrowLeftIcon }) => {
  const [isListView, setIsListView] = useState(isListVieDef);
  
  const toggleListView = () => {
    setIsListView((prev) => !prev);
  };

  return (
 <div> 
      <div
        className={`gettingHere-selector absolute ${
          isMobile
            ? isListView
              ? "flex-col"
              : "bottom-20 flex-row"
            : "top-16 flex-col left-1 pl-4"
        } rounded-lg z-10 size-full`}
        style={{
          marginLeft: `calc((${isMobile ? 0 : 0.65})* (3rem + 1rem))`,
          height: `${isMobile ? (isListView ? 50 : 15.5) : 25}rem`,
          width: `${isMobile ? 100 : 30}%`,
          // overflowX: "hidden",
        }}
      >
       
         {/* Toggle View Button */}
         {isMobile &&(
            <div
              className={`flex ${
                isListView && isMobile ? "justify-start" : "justify-end"
              } items-center p-2`}
              
              style={
                isListView ? {
                position: "sticky", // Sticks to the top when scrolling
                top: 0, // Stick at the top of the scrollable container
                zIndex: 50, // Ensure it stays above other elements
                backgroundColor: "white", // Prevent overlap issues when scrolling
              } : {}}
            >
              <button
                className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 border border-blue-400 hover:bg-blue-200"
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={toggleListView}
              >
                <span style={{ fontSize: "1.2rem", lineHeight: "1" }}>
                {isListView ? <ArrowLeftIcon className="w-4 h-4" /> : "≡"}
                </span>{" "}
                <span>{isListView ? "Back" : "List View"}</span>
              </button>
            
              {isMobile && isListView && (
               // <span style={{paddingLeft: "10px"}}>Places</span>
                <h3 className="ml-4 text-lg font-semibold">Places</h3>
              )}
            </div>
          )}


       <div
            className={`pl-2 pr-1 custom-scrollbar flex ${
              isMobile ? isListView ? "flex-col" : "flex-row": "flex-col"
            }`}
            style={{
              overflowY: "auto",
              overflowX: isListView ? "hidden" : "auto",
              paddingTop: "1rem",
              maxHeight: isMobile ? "80vh" : "70vh", // Set maximum height for desktop and mobile
              gap: "0.5rem"// Add space between items
            }}
          >
        {loungesdata.children.map((lounge) => (
          <div
            key={lounge.id}
            className="flex bg-white rounded-xl shadow-md overflow-hidden mb-2 flex-none"
            style={{
              width: `${isMobile ? (isListView ? "99%" : "80vw") : "100%"}`,
            }}
          >
            <img
              src={lounge.image}
              alt={lounge.name}
              className="w-1/3 h-full object-cover"
            />
            <div className="flex flex-col justify-between p-4 w-2/3">
              <div className="flex justify-between items-start">
                <h3 className="text-xl font-semibold">{lounge.name}</h3>
                <button className="text-gray-500 hover:text-blue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 5l7-2 7 2v14a2 2 0 01-2 2H7a2 2 0 01-2-2V5z"
                    />
                  </svg>
                </button>
              </div>
              <div  className="flex items-center pl-12 px-2 py-2 mt-2">
                  <button className="flex items-center text-blue-500 border border-blue-500 px-5 py-2 rounded-md mr-2 hover:bg-blue-500 hover:text-white">
                    View
                  </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>  
  );
};

export default LoungesSelector;
